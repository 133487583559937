import { Course } from "@cpe/models/course";
import { CourseDetailsMergedResponseModel, CourseDetailsResponseModel } from "@cpe/services/courses";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CourseDetails,
  CourseDetailsComplianceInfo,
  CourseDetailsMetadata,
  CourseDetailsRelations,
  CourseDetailsReviews,
  CourseDetailsStudentState,
  SearchWebcast
} from "./thunks";

export interface CoursesDetailsState {
  // Delete after the removal of CDP_FRAGMENT_API
  details_DEPRECATED: CourseDetailsResponseModel | undefined;
  // ---------------------------------------------

  details: CourseDetailsMergedResponseModel | undefined;
  courses: Course[] | undefined;
  certificateProgram: Record<string, Course>;
  loading: boolean;
  loadingWebcasts: boolean;
  loadingMetadata: boolean;
  loadingReviews: boolean;
  loadingRelations: boolean;
  loadingState: boolean;
  loadingCompliance: boolean;
  metadataError: string | null;
  reviewsError: string | null;
  relationsError: string | null;
  stateError: string | null;
  complianceError: string | null;
  error: string | null;
}
export const initialState: CoursesDetailsState = {
  // Delete after the removal of CDP_FRAGMENT_API
  details_DEPRECATED: undefined,
  // ---------------------------------------------

  details: undefined,
  courses: undefined,
  certificateProgram: {},
  loading: false,
  loadingWebcasts: false,
  loadingMetadata: false,
  loadingReviews: false,
  loadingRelations: false,
  loadingState: false,
  loadingCompliance: false,
  metadataError: null,
  reviewsError: null,
  relationsError: null,
  stateError: null,
  complianceError: null,
  error: null
};

export const courseDetailsSlice = createSlice({
  name: "coursesDetails",
  initialState: initialState,
  reducers: {
    // Delete after the removal of CDP_FRAGMENT_API
    registerCourseDetails_DEPRECATED: state => {
      if (state.details_DEPRECATED) {
        state.details_DEPRECATED.actions = state.details_DEPRECATED?.actions.filter(action => action !== "REGISTER");
        state.details_DEPRECATED.actions.push("UNREGISTER");
        state.details_DEPRECATED.state.registered = true;
      }
    },
    registerSessionCardWebcast_DEPRECATED: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(action => action !== "REGISTER");
        state.courses[courseIndex].actions.push("UNREGISTER");
        state.courses[courseIndex].state.registered = true;
      }
    },
    registerCertificateProgram_DEPRECATED: (state, action: PayloadAction<{ productId: string }> | undefined) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].actions = state.certificateProgram[
            action.payload.productId
          ].actions.filter(action => action !== "REGISTER");
          state.certificateProgram[action.payload.productId].actions.push("UNREGISTER");
          state.certificateProgram[action.payload.productId].state.registered = true;
        }
      }
    },
    unregisterCourseDetails_DEPRECATED: state => {
      if (state.details_DEPRECATED) {
        state.details_DEPRECATED.actions = state.details_DEPRECATED?.actions.filter(action => action !== "UNREGISTER");
        state.details_DEPRECATED.actions.push("REGISTER");
        state.details_DEPRECATED.state.registered = false;
      }
    },
    unregisterSessionCardCourseWebcast_DEPRECATED: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(
          action => action !== "UNREGISTER"
        );
        state.courses[courseIndex].actions.push("REGISTER");
        state.courses[courseIndex].state.registered = false;
      }
    },
    unregisterCertificateProgram_DEPRECATED: (state, action: PayloadAction<{ productId: string }>) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].actions = state.certificateProgram[
            action.payload.productId
          ].actions.filter(action => action !== "UNREGISTER");
          state.certificateProgram[action.payload.productId].actions.push("REGISTER");
          state.certificateProgram[action.payload.productId].state.registered = false;
        }
      }
    },
    joinWebcastCourseDetails_DEPRECATED: state => {
      if (state.details_DEPRECATED) {
        state.details_DEPRECATED.status = "IN_PROGRESS";
      }
    },
    joinSessionCardCourseWebcast_DEPRECATED: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].status = "IN_PROGRESS";
      }
    },
    joinWebcastCertificateProgram_DEPRECATED: (state, action?: PayloadAction<{ productId: string }>) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].status = "IN_PROGRESS";
        }
      }
    },
    toogleBookmarkCertificateProgram_DEPRECATED: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.certificateProgram) {
        state.certificateProgram[action.payload.productId].state.saved =
          !state.certificateProgram[action.payload.productId].state.saved;
      }
    },
    // ---------------------------------------------

    registerCourseDetails: state => {
      if (state.details?.actions && state.details?.state) {
        state.details.actions = state.details?.actions.filter(action => action !== "REGISTER");
        state.details.actions.push("UNREGISTER");
        state.details.state.registered = true;
      }
    },
    registerSessionCardWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(action => action !== "REGISTER");
        state.courses[courseIndex].actions.push("UNREGISTER");
        state.courses[courseIndex].state.registered = true;
      }
    },
    registerCertificateProgram: (state, action: PayloadAction<{ productId: string }> | undefined) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].actions = state.certificateProgram[
            action.payload.productId
          ].actions.filter(action => action !== "REGISTER");
          state.certificateProgram[action.payload.productId].actions.push("UNREGISTER");
          state.certificateProgram[action.payload.productId].state.registered = true;
        }
      }
    },
    unregisterCourseDetails: state => {
      if (state.details?.actions && state.details.state) {
        state.details.actions = state.details?.actions.filter(action => action !== "UNREGISTER");
        state.details.actions.push("REGISTER");
        state.details.state.registered = false;
      }
    },
    unregisterSessionCardCourseWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].actions = state.courses[courseIndex].actions.filter(
          action => action !== "UNREGISTER"
        );
        state.courses[courseIndex].actions.push("REGISTER");
        state.courses[courseIndex].state.registered = false;
      }
    },
    unregisterCertificateProgram: (state, action: PayloadAction<{ productId: string }>) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].actions = state.certificateProgram[
            action.payload.productId
          ].actions.filter(action => action !== "UNREGISTER");
          state.certificateProgram[action.payload.productId].actions.push("REGISTER");
          state.certificateProgram[action.payload.productId].state.registered = false;
        }
      }
    },
    joinWebcastCourseDetails: state => {
      if (state.details && state.details.status) {
        state.details.status = "IN_PROGRESS";
      }
    },
    joinSessionCardCourseWebcast: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.courses && state.courses?.length > 0) {
        const courseIndex = state.courses.findIndex(course => course.productId === action.payload.productId);
        state.courses[courseIndex].status = "IN_PROGRESS";
      }
    },
    joinWebcastCertificateProgram: (state, action?: PayloadAction<{ productId: string }>) => {
      if (action?.payload?.productId) {
        if (state.certificateProgram[action.payload.productId]) {
          state.certificateProgram[action.payload.productId].status = "IN_PROGRESS";
        }
      }
    },
    toogleBookmarkCertificateProgram: (state, action: PayloadAction<{ productId: string }>) => {
      if (state.certificateProgram) {
        state.certificateProgram[action.payload.productId].state.saved =
          !state.certificateProgram[action.payload.productId].state.saved;
      }
    },
    resetDetails: state => {
      state.details = undefined;
    }
  },
  extraReducers: builder => {
    // Delete after the removal of CDP_FRAGMENT_API
    builder.addCase(CourseDetails.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.details_DEPRECATED = undefined;
      } else {
        if (response) {
          state.details_DEPRECATED = response;
          response.children &&
            response.children.map(cp => {
              state.certificateProgram[cp.productId] = cp;
            });
        }
        state.error = "";
      }
      state.loading = false;
    });
    builder.addCase(CourseDetails.rejected, state => {
      state.loading = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetails.pending, state => {
      state.error = "";
      state.loading = true;
    });
    // ---------------------------------------------
    builder.addCase(SearchWebcast.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.error = response?.message || "Something is wrong.";
        state.courses = undefined;
      } else {
        state.courses = action.payload?.courses;
        state.error = "";
      }
      state.loadingWebcasts = false;
    });
    builder.addCase(SearchWebcast.rejected, state => {
      state.loadingWebcasts = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(SearchWebcast.pending, state => {
      state.error = "";
      state.loadingWebcasts = true;
    });
    // Metadata
    builder.addCase(CourseDetailsMetadata.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.metadataError = response?.message || "Something is wrong.";
        state.details = state.details ? { ...state.details } : undefined;
      } else {
        if (response) {
          state.details = state.details ? { ...state.details, ...response } : response;
        }
        state.error = "";
      }
      state.loadingMetadata = false;
      if (
        !state.loadingMetadata &&
        !state.loadingReviews &&
        !state.loadingRelations &&
        !state.loadingState &&
        !state.loadingCompliance
      ) {
        state.loading = false;
      }
    });
    builder.addCase(CourseDetailsMetadata.rejected, state => {
      state.loadingMetadata = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetailsMetadata.pending, state => {
      state.error = "";
      state.loadingMetadata = true;
      state.loading = true;
    });
    // Reviews
    builder.addCase(CourseDetailsReviews.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.reviewsError = response?.message || "Something is wrong.";
        state.details = state.details ? { ...state.details } : undefined;
      } else {
        if (response) {
          state.details = state.details ? { ...state.details, ...response } : response;
        }
        state.error = "";
      }
      state.loadingReviews = false;
      if (
        !state.loadingMetadata &&
        !state.loadingReviews &&
        !state.loadingRelations &&
        !state.loadingState &&
        !state.loadingCompliance
      ) {
        state.loading = false;
      }
    });
    builder.addCase(CourseDetailsReviews.rejected, state => {
      state.loadingReviews = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetailsReviews.pending, state => {
      state.error = "";
      state.loadingReviews = true;
      state.loading = true;
    });
    // Relations
    builder.addCase(CourseDetailsRelations.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.relationsError = response?.message || "Something is wrong.";
        state.details = state.details ? { ...state.details } : undefined;
      } else {
        if (response) {
          state.details = state.details ? { ...state.details, ...response } : response;
          response.children &&
            response.children.map(cp => {
              state.certificateProgram[cp.productId] = cp;
            });
        }
        state.error = "";
      }
      state.loadingRelations = false;
      if (
        !state.loadingMetadata &&
        !state.loadingReviews &&
        !state.loadingRelations &&
        !state.loadingState &&
        !state.loadingCompliance
      ) {
        state.loading = false;
      }
    });
    builder.addCase(CourseDetailsRelations.rejected, state => {
      state.loadingRelations = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetailsRelations.pending, state => {
      state.error = "";
      state.loadingRelations = true;
      state.loading = true;
    });
    // Student state
    builder.addCase(CourseDetailsStudentState.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.stateError = response?.message || "Something is wrong.";
        state.details = state.details ? { ...state.details } : undefined;
      } else {
        if (response) {
          state.details = state.details ? { ...state.details, ...response } : response;
        }
        state.error = "";
      }
      state.loadingState = false;
      if (
        !state.loadingMetadata &&
        !state.loadingReviews &&
        !state.loadingRelations &&
        !state.loadingState &&
        !state.loadingCompliance
      ) {
        state.loading = false;
      }
    });
    builder.addCase(CourseDetailsStudentState.rejected, state => {
      state.loadingState = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetailsStudentState.pending, state => {
      state.error = "";
      state.loadingState = true;
      state.loading = true;
    });
    // Compliance
    builder.addCase(CourseDetailsComplianceInfo.fulfilled, (state, action) => {
      const response = action.payload;

      if (response?.statusCode) {
        state.complianceError = response?.message || "Something is wrong.";
        state.details = state.details ? { ...state.details } : undefined;
      } else {
        if (response) {
          state.details = state.details ? { ...state.details, ...response } : response;
        }
        state.error = "";
      }
      state.loadingCompliance = false;
      if (
        !state.loadingMetadata &&
        !state.loadingReviews &&
        !state.loadingRelations &&
        !state.loadingState &&
        !state.loadingCompliance
      ) {
        state.loading = false;
      }
    });
    builder.addCase(CourseDetailsComplianceInfo.rejected, state => {
      state.loadingCompliance = false;
      state.error = "Something is wrong.";
    });
    builder.addCase(CourseDetailsComplianceInfo.pending, state => {
      state.error = "";
      state.loadingCompliance = true;
      state.loading = true;
    });
  }
});

export const {
  // Delete after the removal of CDP_FRAGMENT_API
  registerCourseDetails_DEPRECATED,
  registerSessionCardWebcast_DEPRECATED,
  registerCertificateProgram_DEPRECATED,
  unregisterCourseDetails_DEPRECATED,
  unregisterSessionCardCourseWebcast_DEPRECATED,
  unregisterCertificateProgram_DEPRECATED,
  joinWebcastCourseDetails_DEPRECATED,
  joinSessionCardCourseWebcast_DEPRECATED,
  joinWebcastCertificateProgram_DEPRECATED,
  toogleBookmarkCertificateProgram_DEPRECATED,
  // ---------------------------------------------
  registerCourseDetails,
  registerSessionCardWebcast,
  registerCertificateProgram,
  unregisterCourseDetails,
  unregisterSessionCardCourseWebcast,
  unregisterCertificateProgram,
  joinWebcastCourseDetails,
  joinSessionCardCourseWebcast,
  joinWebcastCertificateProgram,
  toogleBookmarkCertificateProgram,
  resetDetails
} = courseDetailsSlice.actions;
export default courseDetailsSlice.reducer;
