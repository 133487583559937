import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { useUser } from "../hooks";

export const MyCoursesRedirect: FC = () => {
  const { user } = useUser();
  const isB2b = user.isB2B;

  if (!isB2b) {
    return <Redirect to={"/courses/mycourses"} />;
  }

  return <Redirect to={"/courses/all"} />;
};
