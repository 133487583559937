import {
  CourseDetailsComplianceInfoResponseModel,
  CourseDetailsRelationsResponseModel,
  CourseDetailsResponseModel,
  CourseDetailsReviewsResponseModel,
  CourseDetailsStudentStateResponseModel,
  CourseDetaislMetadataResponseModel,
  CoursesResponseModel,
  CoursesService
} from "@cpe/services/courses";
import { ErrorResponse } from "@cpe/services/fetch-util";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const CourseDetails = createAsyncThunk<
  (ErrorResponse & CourseDetailsResponseModel) | undefined,
  string | undefined,
  any
>("course/details/get", async (productId: string) => (await CoursesService.getCourseDetails(productId)).parsedBody);

export const SearchWebcast = createAsyncThunk<
  (ErrorResponse & CoursesResponseModel) | undefined,
  string | undefined,
  any
>("search/webcast/get", async (search: string) => (await CoursesService.searchWebcasts(search)).parsedBody);

export const CourseDetailsMetadata = createAsyncThunk<
  (ErrorResponse & CourseDetaislMetadataResponseModel) | undefined,
  string | undefined,
  any
>(
  "course/details/get/meta",
  async (productId: string) => (await CoursesService.getCourseDetailsMetadata(productId)).parsedBody
);

export const CourseDetailsReviews = createAsyncThunk<
  (ErrorResponse & CourseDetailsReviewsResponseModel) | undefined,
  string | undefined,
  any
>(
  "course/details/get/reviews",
  async (productId: string) => (await CoursesService.getCourseDetailsReviews(productId)).parsedBody
);

export const CourseDetailsRelations = createAsyncThunk<
  (ErrorResponse & CourseDetailsRelationsResponseModel) | undefined,
  string | undefined,
  any
>(
  "course/details/get/relations",
  async (productId: string) => (await CoursesService.getCourseDetailsRelations(productId)).parsedBody
);

export const CourseDetailsStudentState = createAsyncThunk<
  (ErrorResponse & CourseDetailsStudentStateResponseModel) | undefined,
  string | undefined,
  any
>(
  "course/details/get/state",
  async (productId: string) => (await CoursesService.getCourseDetailsStudentState(productId)).parsedBody
);

export const CourseDetailsComplianceInfo = createAsyncThunk<
  (ErrorResponse & CourseDetailsComplianceInfoResponseModel) | undefined,
  string | undefined,
  any
>(
  "course/details/get/compliance",
  async (productId: string) => (await CoursesService.getCourseDetailsComplianceInfo(productId)).parsedBody
);
